<div class="text-center pageTitle1">
  <br>
  <h1>Admin Pannel</h1>
  <hr>
</div>


<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Users">
    <div>
      <h2 class="text-center" style="margin:15px;">Users</h2>
      <hr>
      <app-generic-table-simple *ngIf="loadedUsers" [tableColumns]="usersTable.tableColumns"
        [tableData]="usersTable.tableData" [tableActions]="usersTable.tableActions">
      </app-generic-table-simple>

    </div>
  </mat-tab>
  <mat-tab label="Registration tokens">
    <div>
      <h2 class="text-center" style="margin:15px;">Add New Registration Token</h2>
      <hr>
      <app-generic-form [fields]="registrationTokenForm.fields" [actionName]="registrationTokenForm.actionName"
        (onSubmitEvent)="addToken($event)">
      </app-generic-form>
    </div>
    <div>
      <h2 class="text-center" style="margin:15px;">Registration Tokens</h2>
      <hr>
      <app-generic-table-simple *ngIf="loadedRegistrationTokens" [tableColumns]="registrationTokenTable.tableColumns"
        [tableData]="registrationTokenTable.tableData" [tableActions]="registrationTokenTable.tableActions">
      </app-generic-table-simple>
    </div>
  </mat-tab>
</mat-tab-group>
