import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-form-dialog',
  templateUrl: './generic-form-dialog.component.html',
  styleUrls: ['./generic-form-dialog.component.scss']
})
export class GenericFormDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<GenericFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { } // 

  onNoClick(): void {
    this.dialogRef.close();
  }

  sentData(data: any) {
    this.dialogRef.close(data);
  }

}
