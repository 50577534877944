import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { NoteBookDto } from 'src/app/models/dto/noteBookDtoModel';
import { NoteRecordDto } from 'src/app/models/dto/noteRecordDtoModel';
import { NoteBook } from 'src/app/models/standard-model/noteBookModel';
import { NoteCell } from 'src/app/models/standard-model/noteCellModel';
import { NoteColumn } from 'src/app/models/standard-model/noteColumnModel';
import { HttpGenericService } from 'src/app/services/http-generic-service';
import { GenericFormDialogComponent } from '../../utility-components/dialog-components/generic-form-dialog/generic-form-dialog.component';
import { NoteRecordFormDialogComponent } from '../../utility-components/dialog-components/note-record-form-dialog/note-record-form-dialog.component';
import { SimpleActionDialogComponent } from '../../utility-components/dialog-components/simple-action-dialog/simple-action-dialog.component';

@Component({
  selector: 'app-note-book-details',
  templateUrl: './note-book-details.component.html',
  styleUrls: ['./note-book-details.component.scss']
})
export class NoteBookDetailsComponent implements OnInit {

  constructor(private noteBookDtoHttpService: HttpGenericService<NoteBookDto>,
    private noteBookHttpService: HttpGenericService<NoteBook>,
    private noteRecordDtoHttpService: HttpGenericService<NoteRecordDto>,
    private noteColumnHttpService: HttpGenericService<NoteColumn>,
    private genericArrayHttpService: HttpGenericService<any>,
    public dialog: MatDialog,
    private route: ActivatedRoute) { }

  noteBookDto: NoteBookDto = new NoteBookDto;
  tableData: any = {};

  newRecordForm: any = {};

  noteBookDataForm: any;
  columnsDataForm: any;
  addedColumnsList: NoteColumn[] = [];
  columnsList: NoteColumn[] = [];


  noteBookId: number = 0;

  noteBookDtoLoaded: boolean = false;

  ngOnInit(): void {
    this.route.params.subscribe(event => {
      this.noteBookId = event.id;
      this.loadNoteBookDto(this.noteBookId);
    });
    this.loadColumnsDataForm();
  }

  loadNoteBookDto(id: number) {
    this.noteBookDtoHttpService.getById("NoteBook", this.noteBookId).subscribe(response => {
      this.noteBookDto = response;
      this.transformNoteBookDtoIntoTable(this.noteBookDto);
      this.loadNoteBookDataForm(response.noteBook);
      this.columnsList = response.noteColumns;
      this.noteBookDtoLoaded = true;
    })
  }

  transformNoteBookDtoIntoTable(noteBookDto: NoteBookDto) {
    console.log(noteBookDto);
    let table: any = {};
    table['columns'] = [];
    table['records'] = [];

    if (noteBookDto.noteRecords != []) {
      noteBookDto.noteRecords.forEach(record => {
        let newRecord: any = {};

        record.noteCells.forEach(cell => {
          noteBookDto.noteColumns.forEach(column => {
            if (cell.noteColumnId == column.id) {
              newRecord[column.key] = cell.data;
            }
          });
        });
        newRecord['id'] = record.noteRecord.id;
        table['records'].push(newRecord);
      });
    }

    noteBookDto.noteColumns.forEach(column => {
      table['columns'].push({ name: column.name, key: column.key });
    })

    this.tableData = table;
    console.log(this.tableData);
  }

  addRecord(data: NoteRecordDto) {
    this.noteRecordDtoHttpService.post("NoteBook/" + this.noteBookId + "/Note", data).subscribe(response => {
      // console.log(response);
      this.reloadCurrentPage();
    });
  }

  addColumn() {
    this.openGenericFormDialog("Add column", this.columnsDataForm).subscribe(dialogResp => {
      if (dialogResp != null) {
        this.genericArrayHttpService.post("NoteBook/" + this.noteBookId + "/NoteColumn", [dialogResp]).subscribe(response => {
          console.log(response);
          this.reloadCurrentPage();
        })
      }
    })
  }

  updateRecord(id: number) {
    let noteRecordDto: NoteRecordDto = new NoteRecordDto;
    this.noteBookDto.noteRecords.forEach(record => {
      if (record.noteRecord.id == id) {
        noteRecordDto = record;
      }
    })

    this.openNoteRecordFormDialog("Update Record", noteRecordDto, this.columnsList).subscribe(dialogResp => {
      if (dialogResp != null) {
        console.log(dialogResp);
        this.noteRecordDtoHttpService.put("NoteBook/" + this.noteBookId + "/Note", dialogResp, id).subscribe(response => {
          console.log(response);
          this.reloadCurrentPage();
        })
      }
    })

  }

  updateNoteBookData(noteBookUpdatedData: any) {
    let updatedNoteBook = this.noteBookDto.noteBook;
    updatedNoteBook.name = noteBookUpdatedData.name;
    updatedNoteBook.description = noteBookUpdatedData.description;

    this.noteBookHttpService.put("NoteBook", updatedNoteBook, updatedNoteBook.id).subscribe(response => {
      this.reloadCurrentPage();
    })
  }

  loadNoteBookDataForm(noteTableData: NoteBook) {
    this.noteBookDataForm = { fields: [{ displayName: "Name", key: "name", value: noteTableData.name, type: "text" }, { displayName: "Description", key: "description", value: noteTableData.description, type: "text" }], actionName: "Save" }
  }

  loadColumnsDataForm() {
    this.columnsDataForm = {
      fields: [{ displayName: "Name", key: "name", value: "", type: "text" },
      { displayName: "Type", key: "type", value: "text", type: "dropdown", list: [{ display: "Number", value: "number" }, { display: "Text", value: "text" }, { display: "DateTime", value: "datetime" }] }],
      actionName: "Add"
    }
  }

  removeRecord(id: number) {
    this.openSimpleDialog("Delete record", "A deleted record can't be restored.", "Cancel", "Confirm").subscribe(dialogResp => {
      if (dialogResp == true) {
        this.noteRecordDtoHttpService.delete("NoteBook/" + this.noteBookId + "/Note", id).subscribe(response => {
          console.log(response);
          this.reloadCurrentPage();
        })
      }
    })
  }

  removeColumnOfTable(column: NoteColumn) {
    this.openSimpleDialog("Delete column: " + column.name, "By deleting this column all data of it will be deleted.", "Cancel", "Confirm").subscribe(dialogResp => {
      if (dialogResp == true) {
        this.noteColumnHttpService.delete("NoteBook/" + this.noteBookId + "/NoteColumn", column.id).subscribe(response => {
          console.log(response);
          this.reloadCurrentPage();
        })
      }
    })
  }

  columnMovePosition(id: number, direction: number) {
    this.noteColumnHttpService.post("NoteBook/" + this.noteBookId + "/NoteColumn/" + id + "/MovePosition/" + direction, new NoteColumn).subscribe(response => {
      console.log(response);
      this.reloadCurrentPage();
    })
  }

  addColumnToList(columnsDataForm: any) {
    console.log(columnsDataForm);
    this.addedColumnsList.push(columnsDataForm);
    this.loadColumnsDataForm();
    //this.nextStepInAddTableAction();
  }

  removeFromColumnList(indexOfColumn: number) {
    console.log(indexOfColumn);
    this.addedColumnsList.splice(indexOfColumn, 1);
    console.log(this.addedColumnsList);
  }


  reloadCurrentPage() {
    window.location.reload();
  }

  openSimpleDialog(title: string, body: string, declineButtonText: string, confirmButtonText: string): Observable<any> {
    const dialogRef = this.dialog.open(SimpleActionDialogComponent, {
      width: '50%',
      data: { title: title, body: body, declineButtonText: declineButtonText, confirmButtonText: confirmButtonText }
    });

    return dialogRef.afterClosed();
  }

  openGenericFormDialog(title: string, formSchema: any): Observable<any> {
    const dialogRef = this.dialog.open(GenericFormDialogComponent, {
      width: '50%',
      data: { title: title, formSchema: formSchema }
    });

    return dialogRef.afterClosed();
  }

  openNoteRecordFormDialog(title: string, noteRecordDto: NoteRecordDto, noteColumns: NoteColumn[]): Observable<any> {
    console.log({ title: title, noteRecordDto: noteRecordDto, columns: noteColumns });
    const dialogRef = this.dialog.open(NoteRecordFormDialogComponent, {
      width: '50%',
      data: { title: title, noteRecordDto: noteRecordDto, noteColumns: noteColumns }
    });

    return dialogRef.afterClosed();
  }

}
