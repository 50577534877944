import { BaseModel } from "./baseModel";

export class NoteCell extends BaseModel{

  constructor() {
    super();
    this.noteRecordId = 0;
    this.noteColumnId = 0;
    this.data = "";
  }

  "noteRecordId": number;
  "noteColumnId": number;
  "data": string;
}
