<div class="container-fluid row gradientBg" style="width:100%;min-height: 1000px; margin: 0;">
  <div class="col-sm-3"></div>
  <div class="col-sm-6"
    style="margin: auto;margin-top: 5%;padding: 15px;background-color: white;border-radius: 2%;padding: 8%;">
    <h1 class="text-center">Log in</h1>
    <hr>

    <div style="margin: auto;width: 100%;">
      <p *ngIf="haveErrrors" style="color:red">{{errorsList}}</p>
      <app-generic-form [fields]="loginForm.fields" [actionName]="loginForm.actionName"
        (onSubmitEvent)="loginUser($event)">
      </app-generic-form>
    </div>

  </div>
</div>
