<div class="text-center pageTitle1">
  <br>
  <h1>Notes Menu</h1>
  <hr>
</div>
<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Tables">
    <div *ngIf="loadedNoteBooks">
      <div *ngFor="let noteBook of noteBooks" style="margin:50px;">
        <mat-card class="example-card" style="background-color: #7986cb;color: white;max-width: 80%;margin:auto">
          <!-- rgba(76,100,127,1.00)-->
          <mat-card-subtitle>Name:</mat-card-subtitle>
          <mat-card-title>{{ noteBook.name }}</mat-card-title>
          <mat-card-content>
            <p>Description:</p>
            <p>{{ noteBook.description }}</p>
          </mat-card-content>
          <hr>
          <mat-card-actions>
            <button mat-button routerLink="/note/{{noteBook.id}}" routerLinkActive="active">Details</button>
            <button mat-button color="warn" (click)="deleteTable(noteBook)">Delete</button>
          </mat-card-actions>
          <mat-card-footer>
          </mat-card-footer>
        </mat-card>
      </div>
      <div *ngIf="noteBooks.length <= 0">
        <h2 class="text-center" style="margin:15px">0 tables</h2>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Add table">
    <div style="padding: 15px;">
      <div class="text-center ">
        <h1>Add Table</h1>
        <hr>
        <h3>Follow the steps to insert a new table.</h3>
      </div>
      <div *ngIf="loadedNoteBookDataForm">
        <div>
          <br>
        </div>
        <div [ngSwitch]="currentStepInAddTableAction">

          <div *ngSwitchCase="1">
            <div style="width: 100%;" class="text-center">
              <h2>Insert table data</h2>
              <div class="btn-group" role="group">
                <a type="button" class="btn btn-primary" (click)="nextStepInAddTableAction()">Next</a>
              </div>
            </div>
            <div class="margin:20px;">
              <div>
                <h3></h3>
              </div>
              <app-generic-form [fields]="noteBookDataForm.fields" [actionName]="noteBookDataForm.actionName"
                (onSubmitEvent)="addNoteBookData($event)">
              </app-generic-form>
            </div>
          </div>
          <div *ngSwitchCase="2">
            <div style="width: 100%;" class="text-center">
              <h2>Insert the columns of table</h2>
              <div class="btn-group" role="group">
                <a type="button" class="btn btn-primary" (click)="backStepInAddTableAction()">Back</a>
                <a type="button" class="btn btn-primary" (click)="nextStepInAddTableAction()">Next</a>
              </div>
            </div>
            <div class="padding:20px;">
              <div>
                <h3></h3>
              </div>
              <app-generic-form [fields]="columnsDataForm.fields" [actionName]="columnsDataForm.actionName"
                (onSubmitEvent)="addColumnToList($event)">
              </app-generic-form>
              <div>
                <div *ngIf="addedColumnsList.length > 0" class="text-center">
                  <h3>Added columns:</h3><br>
                  <div *ngFor="let column of addedColumnsList; let i = index">
                    <b>Column: </b> {{column.name}} | <b>Type: </b> {{column.type}} | <a
                      style="color:red; cursor: pointer;" (click)="removeFromColumnList(i)">Delete</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngSwitchCase="3">
            <div style="width: 100%;" class="text-center">
              <h2>Finish registration</h2>
              <div class="btn-group" role="group">
                <a type="button" class="btn btn-primary" (click)="backStepInAddTableAction()">Back</a>
                <a type="button" class="btn btn-primary" (click)="addNewTableToDb()"
                  *ngIf="(noteTableData.name != '' && noteTableData.name != null) && addedColumnsList.length > 0">Finsh</a>
              </div>
            </div>
            <div class="padding:20px;">
              <div>
                <h3></h3>
              </div>
              <div class="text-center" *ngIf="noteTableData.name != '' && noteTableData.name != null">
                <h3>Added columns:</h3>
                <b>Name: </b> {{noteTableData.name}}<br>
                <b>Description: </b> {{noteTableData.description}}<br>
              </div>
              <div class="text-center" *ngIf="noteTableData.name == '' || noteTableData.name == null">
                <b style="color: red">*Name can't be empty or null!</b>
              </div><br><br><br>
              <div *ngIf="addedColumnsList.length > 0" class="text-center">
                <h3>Added columns:</h3>
                <div *ngFor="let column of addedColumnsList; let i = index">
                  <b>Column: </b> {{column.name}} | <b>Type: </b> {{column.type}}
                </div>
              </div>
              <div *ngIf="addedColumnsList.length <= 0" class="text-center">
                <b style="color: red">*Columns list can't be empty!</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
