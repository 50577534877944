import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-generic-table-simple',
  templateUrl: './generic-table-simple.component.html',
  styleUrls: ['./generic-table-simple.component.scss']
})
export class GenericTableSimpleComponent implements OnInit {


  @Input() tableColumns: any[] = [];
  @Input() tableData: any[] = [];
  @Input() tableActions: string[] = [];

  @Output() onActionEvent = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }

  onAction(id: any, action: string) {
    this.onActionEvent.emit({ "id": id, "action": action });
  }

}
