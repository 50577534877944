import { NoteBook } from "../standard-model/noteBookModel";
import { NoteColumn } from "../standard-model/noteColumnModel";
import { NoteRecordDto } from "./noteRecordDtoModel";

export class NewNoteBookDto {

  constructor() {
    this.noteBook = new NoteBook();
    this.noteColumns = [];
  }

  "noteBook": NoteBook;
  "noteColumns": NoteColumn[];
}
