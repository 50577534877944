import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenActionsService } from './services/token-actions-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Client';

  actualRank: string = "";
  actualUser: string = "";

  loggedIn: boolean = false;

  constructor(private tokenService: TokenActionsService, private routerMain: Router) { //HttpService<UserModel> // private routerMain: Router, private authDataServiceMain: TokenActionsService
    this.setNavData();
  }


  setNavData() {
    if (this.tokenService.isAuthenticated()) {
      this.loggedIn = true;
      this.actualRank = this.tokenService.getCurrentRank() // this.authDataServiceMain.getCurrentRank();
      this.actualUser = this.tokenService.getCurrentEmail()// this.authDataServiceMain.getCurrentEmail();
      // this.regularCheckIfIsLoggedIn();
    }
    else {
      this.loggedIn = false;
      this.actualRank = "No user logged in" // this.authDataServiceMain.getCurrentRank();
      this.actualUser = "No user logged in"// this.authDataServiceMain.getCurrentEmail();
    }
  }


  pagesRedirection() {
    /*
    switch (this.authDataServiceMain.getCurrentRank()) {
      case "admin": {
        this.routerMain.navigate(['/admin']);
        break;
      }
      case "teacher": {
        this.authDataServiceMain.deleteToken();
        this.reloadCurrentPage();
        this.routerMain.navigate(['/auth']);
        //  this.error("The pages for this user are not implemented!");
        break;
      }
      case "student": {
        this.routerMain.navigate(['/student']);
        break;
      }
      default: {
        this.authDataServiceMain.deleteToken();
        this.reloadCurrentPage();
        this.routerMain.navigate(['/auth']);
        //   this.error("Invalid user!");
        break;
      }
    }
    */
  }

  logOut() {
    this.tokenService.deleteToken();
    this.setNavData();
    this.routerMain.navigate(['/LogIn']);
  }

  logIn() {
    this.tokenService.deleteToken();
    this.setNavData();
    this.routerMain.navigate(['/LogIn']);
  }

  async regularCheckIfIsLoggedIn(): Promise<any> {
    let run = true;

    while (run == true) {
      setTimeout(() => {
        if (this.loggedIn && !this.tokenService.isAuthenticated()) {
          this.reloadCurrentPage();
        } else {
          console.log("Still logged in!")
        }
      },
        10 * 60 * 1000);
    }

  }

  reloadCurrentPage() {
    window.location.reload();
  }

}
