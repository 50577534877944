import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-simple-action-dialog',
  templateUrl: './simple-action-dialog.component.html',
  styleUrls: ['./simple-action-dialog.component.scss']
})
export class SimpleActionDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<SimpleActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
