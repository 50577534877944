import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { TokenActionsService } from './token-actions-service';

@Injectable({
  providedIn: 'root'
})

export class AdminAuthGuardService implements CanActivate {
  constructor(public tokenService: TokenActionsService, public router: Router) { }

  canActivate(): boolean {
    if (!this.tokenService.isAuthenticated("admin")) {
      //this.tokenService.deleteToken();
      this.router.navigate(['404']);
      return false;
    }
    return true;
  }
}
