import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GenericFormComponent } from './components/utility-components/generics/generic-form/generic-form.component';
import { HttpClientModule } from '@angular/common/http';
import { NoteRecordFormComponent } from './components/utility-components/generics/note-record-form/note-record-form.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

import { NoteBookInfoComponent } from './components/pages/note-book-info/note-book-info.component';
import { NotesMainComponent } from './components/pages/notes-main/notes-main.component';
import { GenericTableSimpleComponent } from './components/utility-components/generics/generic-tables/generic-table-simple/generic-table-simple.component';
import { LoginComponent } from './components/pages/login/login.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { NoteBookDetailsComponent } from './components/pages/note-book-details/note-book-details.component';
import { HomeComponent } from './components/pages/home/home.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { SimpleActionDialogComponent } from './components/utility-components/dialog-components/simple-action-dialog/simple-action-dialog.component';
import { GenericFormDialogComponent } from './components/utility-components/dialog-components/generic-form-dialog/generic-form-dialog.component';
import { NoteRecordFormDialogComponent } from './components/utility-components/dialog-components/note-record-form-dialog/note-record-form-dialog.component';
import { AdminPannelComponent } from './components/pages/admin-pannel/admin-pannel.component';

@NgModule({
  declarations: [
    AppComponent,
    GenericFormComponent,
    NoteRecordFormComponent,
    NoteBookInfoComponent,
    NotesMainComponent,
    GenericTableSimpleComponent,
    LoginComponent,
    RegisterComponent,
    NoteBookDetailsComponent,
    HomeComponent,
    NotFoundComponent,
    SimpleActionDialogComponent,
    GenericFormDialogComponent,
    NoteRecordFormDialogComponent,
    AdminPannelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,




    MatInputModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatRippleModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatTableModule,
    MatChipsModule,
    MatIconModule,
    MatDialogModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
