import { BaseModel } from "./baseModel";

export class NoteBook extends BaseModel {

  constructor() {
    super();
    this.name = "";
    this.description = "";
    this.userId = 0;
    this.id = 0;
    this.registrationDate = new Date();
    this.modifiedDate = new Date();
  }

  "userId": number;
  "name": string;
  "description": string;
}
