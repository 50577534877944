import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NoteRecordDto } from 'src/app/models/dto/noteRecordDtoModel';
import { NoteCell } from 'src/app/models/standard-model/noteCellModel';
import { NoteColumn } from 'src/app/models/standard-model/noteColumnModel';

@Component({
  selector: 'app-note-record-form',
  templateUrl: './note-record-form.component.html',
  styleUrls: ['./note-record-form.component.scss']
})
export class NoteRecordFormComponent implements OnInit { //https://angular.io/guide/dynamic-form - reactive forms

  @Input() columns: NoteColumn[] = [];

  @Input() noteRecordDto: NoteRecordDto = new NoteRecordDto;

  @Output() onSubmitEvent = new EventEmitter<NoteRecordDto>();

  allDataReady: boolean = false;

  // loadedColumns: boolean = false;
  // loadedNoteRecordDtos: boolean = false;


  /* EXAMPLE
  userDataForm: FormGroup = new FormGroup({
   rank: new FormControl(''),
   fullName: new FormControl(''),
   email: new FormControl(''),
   passwordHash: new FormControl('')
 });
 */

  genericForm: FormGroup = new FormGroup({});

  constructor() { }

  ngOnInit(): void {

    this.generateFormGroup(this.columns);

    if (this.noteRecordDto.noteCells != []) {
      this.formatCellsIntoForm(this.columns, this.noteRecordDto);
    }

    this.allDataReady = true;
  }

  formatCellsIntoForm(columns: NoteColumn[], noteRecordDto: NoteRecordDto) {
    console.log({ "columns": columns });
    console.log({ "noteRecordDto": noteRecordDto });
    try {
      columns.forEach(column => {
        noteRecordDto.noteCells.forEach(cell => {
          if (column.id == cell.noteColumnId) {
            switch (column.type) {
              case "datetime":
                this.genericForm.get(column.name)?.patchValue(new Date(cell.data));
                break;
              case "text":
                this.genericForm.get(column.name)?.patchValue(cell.data);
                break;
              case "number":
                this.genericForm.get(column.name)?.patchValue(cell.data);
                break;
              default:
                this.genericForm.get(column.name)?.patchValue(cell.data);
                break;
            }
          }
        });
      });
    }
    catch {

    }

  }

  populateColumn() {
    let column1 = new NoteColumn();
    let column2 = new NoteColumn();
    let column3 = new NoteColumn();

    column1.name = "Nume";
    column1.type = "text";

    column2.name = "Varsta";
    column2.type = "number";

    column3.name = "Coloana";
    column3.type = "number";



    this.columns.push(column1);
    this.columns.push(column2);
    this.columns.push(column3);

    this.generateFormGroup(this.columns);
  }

  generateFormGroup(columns: NoteColumn[]) {
    let newGroup: any = {};
    columns.forEach(column => {
      newGroup[column.name] = new FormControl('');
    })
    this.genericForm = new FormGroup(newGroup);
  }

  onSubmit() {
    this.onSubmitEvent.emit(this.formatFormIntoRecord(this.genericForm.value));
  }

  formatFormIntoRecord(data: any): NoteRecordDto {
    let cellsArray: NoteCell[] = [];

    this.columns.forEach(column => {
      let newNoteCell = new NoteCell;
      if (data[column.name] instanceof Date) {
        newNoteCell.data = data[column.name].toLocaleString();
      }
      else {
        newNoteCell.data = data[column.name].toString();
      }
      //newNoteCell.data = newNoteCell.data.replace(" (Eastern European Summer Time)", "");

      newNoteCell.noteColumnId = column.id;

      try {
        newNoteCell.noteRecordId = this.noteRecordDto.noteRecord.id;
      }
      catch {

      }

      try { // set the id of cell, searching in the old cells list by column id
        this.noteRecordDto.noteCells.forEach(oldCell => {
          if (oldCell.noteColumnId == column.id) {
            newNoteCell.id = oldCell.id;
          }
        })
      }
      catch {

      }

      cellsArray.push(newNoteCell);
    });

    let noteRecordDto = new NoteRecordDto;

    noteRecordDto.noteRecord = this.noteRecordDto.noteRecord;
    noteRecordDto.noteCells = cellsArray;

    return noteRecordDto;
  }

}
