import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { NewNoteBookDto } from 'src/app/models/dto/newNoteBookDtoModel';
import { NewNoteRecordDto } from 'src/app/models/dto/newNoteRecordDtoModel';
import { NoteBook } from 'src/app/models/standard-model/noteBookModel';
import { NoteColumn } from 'src/app/models/standard-model/noteColumnModel';
import { HttpGenericService } from 'src/app/services/http-generic-service';
import { SimpleActionDialogComponent } from '../../utility-components/dialog-components/simple-action-dialog/simple-action-dialog.component';

@Component({
  selector: 'app-notes-main',
  templateUrl: './notes-main.component.html',
  styleUrls: ['./notes-main.component.scss']
})
export class NotesMainComponent implements OnInit {
  constructor(private newNoteBookDtoHttpService: HttpGenericService<NewNoteBookDto>,
    private noteBookHttpService: HttpGenericService<NoteBook>,
    public dialog: MatDialog,
  ) { }

  noteBookDataForm: any;
  columnsDataForm: any;

  noteBooks: NoteBook[] = []; // a list with all notebooks without rows and columns

  addedColumnsList: NoteColumn[] = [];
  noteTableData: NoteBook = new NoteBook;

  loadedNoteBookDataForm: boolean = false;
  loadedNoteBooks: boolean = false;

  currentStepInAddTableAction: number = 1; // 1: table, 2: columns

  ngOnInit(): void {
    this.loadNoteBookDataForm(this.noteTableData);
    this.loadColumnsDataForm();
    this.loadNoteBooks();
    this.loadedNoteBookDataForm = true;
  }

  nextStepInAddTableAction() {
    // to implement protections
    if (this.currentStepInAddTableAction < 3)
      this.currentStepInAddTableAction += 1;
  }

  backStepInAddTableAction() {
    // to implement protections
    if (this.currentStepInAddTableAction > 1)
      this.currentStepInAddTableAction -= 1;
  }

  setStepInAddTableAction(stepNumber: number) {
    this.currentStepInAddTableAction = stepNumber;
  }

  loadNoteBookDataForm(noteTableData: NoteBook) {
    this.noteBookDataForm = { fields: [{ displayName: "Name", key: "name", value: noteTableData.name, type: "text" }, { displayName: "Description", key: "description", value: noteTableData.description, type: "text" }], actionName: "Save" }
  }

  loadColumnsDataForm() {
    this.columnsDataForm = {
      fields: [{ displayName: "Name", key: "name", value: "", type: "text" },
      { displayName: "Type", key: "type", value: "text", type: "dropdown", list: [{ display: "Number", value: "number" }, { display: "Text", value: "text" }, { display: "DateTime", value: "datetime" }] }],
      actionName: "Add"
    }
  }

  loadNoteBooks() {
    this.noteBookHttpService.get("NoteBook").subscribe(response => {
      this.noteBooks = response;
      this.loadedNoteBooks = true;
      console.log(this.noteBooks);
    })
  }

  transpofrmColumnsInGenericFormListArray() {

  }

  addNoteBookData(noteBookDataForm: any) {
    this.noteTableData = noteBookDataForm;
    this.loadNoteBookDataForm(noteBookDataForm);
    console.log(this.noteTableData);
    this.nextStepInAddTableAction();
  }

  addColumnToList(columnsDataForm: any) {
    console.log(columnsDataForm);
    this.addedColumnsList.push(columnsDataForm);
    this.loadColumnsDataForm();
    //this.nextStepInAddTableAction();
  }

  removeFromColumnList(indexOfColumn: number) {
    console.log(indexOfColumn);
    this.addedColumnsList.splice(indexOfColumn, 1);
    console.log(this.addedColumnsList);
  }

  addNewTableToDb() {
    let noteBookDto: NewNoteBookDto = new NewNoteBookDto;
    noteBookDto.noteBook = this.noteTableData;
    noteBookDto.noteColumns = this.addedColumnsList;

    console.log(noteBookDto);
    this.newNoteBookDtoHttpService.post("NoteBook/", noteBookDto).subscribe(response => {
      console.log(response);
      this.reloadCurrentPage();
    });
  }

  deleteTable(noteBook: NoteBook) {
    this.openSimpleDialog("Delete Table: " + noteBook.name, "By deleting the table all data of it will be deleted and will can not be restored.", "Cancel", "Confirm").subscribe(dialogResp => {
      if (dialogResp == true) {
        this.noteBookHttpService.delete("NoteBook", noteBook.id).subscribe(response => {
          console.log(response);
          this.reloadCurrentPage();
        })
      }
    })

  }

  openSimpleDialog(title: string, body: string, declineButtonText: string, confirmButtonText: string): Observable<any> {
    const dialogRef = this.dialog.open(SimpleActionDialogComponent, {
      width: '50%',
      data: { title: title, body: body, declineButtonText: declineButtonText, confirmButtonText: confirmButtonText }
    });

    return dialogRef.afterClosed();
  }

  reloadCurrentPage() {
    window.location.reload();
  }

  //{ displayName: "nameList", key: "key1", value: "", type: "dropdown", list: [{ display: "value1", value: "valueKey1" }, { display: "value2", value: "valueKey2" }] }
}
