<div *ngIf="allDataReady">

</div>

<form (ngSubmit)='onSubmit()' [formGroup]='genericForm' novalidate class="form" style="margin: auto;width: 70%;">
  <mat-form-field *ngFor="let column of columns" [ngSwitch]="column.type" appearance="fill"
    style="margin: auto;width: 100%;" style="width: 100%;">
    <mat-label>{{column.name}}</mat-label>
    <input *ngSwitchCase="'text'" [formControlName]="column.name" type="text" placeholder='{{column.name}}' matInput>
    <input *ngSwitchCase="'number'" [formControlName]="column.name" type="number" placeholder='{{column.name}}'
      matInput>
    <div *ngSwitchCase="'datetime'">
      <input matInput [matDatepicker]="picker" [formControlName]="column.name">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </mat-form-field>
  <br>

  <button class="btn btn-default">Submit</button>
</form>
