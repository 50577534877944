<div class="jumbotron text-center pageTitle1" style="border-radius: 0;">
  <h1>NotesBook App</h1>
  <p>A simple solution for complicated things </p>
</div>

<!-- Container (About Section) -->
<div id="about" class="container-fluid">
  <div class="row">
    <div class="col-sm-8">
      <h2>About Our Product</h2><br>
      <h4>NotesBook is a complex, safe, easy to acces and use data storage.</h4><br>
      <p>The scope of NotesBook is to offer a safe and easy to use datastorage. It offer the posibility to create your
        custom tables and acces the data by any device.</p>
      <br><button class="btn btn-default btn-lg">Get in Touch</button>
    </div>
    <div class="col-sm-1"></div>
    <div class="col-sm-3" style="margin: auto;">
      <div style="margin: auto;">
        <span class="glyphicon glyphicon-briefcase logo" style="width: 100%;"></span>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid bg-grey">
  <div class="row">
    <div class="col-sm-4">
      <span class="glyphicon glyphicon-cog logo "></span>
    </div>
    <div class="col-sm-8">
      <h2>How to use it</h2><br>
      <h4><strong>PRIMARY:</strong> You can start to use our product by creating an account. After theat you will
        configurate the tables and will can start. If you need help you can acces our tutorials or speak with an
        administrator.</h4><br>
      <p><strong>OTHERS:</strong> Our product is complex and constantly changing so the best way to learn how to use it
        is to acces out tutorials.</p>
    </div>
  </div>
</div>

<!-- Container (Services Section) -->
<div id="services" class="container-fluid text-center">
  <h2>SERVICES</h2>
  <h4>What we offer</h4>
  <br>
  <div class="row" style="margin:auto;">
    <div class="col-sm-3" style="margin:auto;">
      <span class="glyphicon glyphicon-flash logo-small"></span>
      <h4>EASY ACCES AND USE</h4>
      <p>An nice interface easy to acces and use on computers and smart phones</p>
    </div>
    <div class="col-sm-3" style="margin:auto;">
      <span class="glyphicon glyphicon-lock logo-small"></span>
      <h4>SAFE DATA STORAGE</h4>
      <p>An safe and encrypted storage</p>
    </div>
    <div class="col-sm-3" style="margin:auto;">
      <span class="glyphicon glyphicon-list-alt logo-small"></span>
      <h4>COMPLEXITY</h4>
      <p>The posibility to create complex and powerfull data storage</p>
    </div>
    <div class="col-sm-3" style="margin:auto;">
      <span class="glyphicon glyphicon-link logo-small"></span>
      <h4>FRIENDS CONNECTION</h4>
      <p>The posibility to share data with friends</p>
    </div>
  </div>
  <br><br>
  <!--
  <div class="row slideanim">
    <div class="col-sm-4">
      <span class="glyphicon glyphicon-leaf logo-small"></span>
      <h4>GREEN</h4>
      <p>Lorem ipsum dolor sit amet..</p>
    </div>
    <div class="col-sm-4">
      <span class="glyphicon glyphicon-certificate logo-small"></span>
      <h4>CERTIFIED</h4>
      <p>Lorem ipsum dolor sit amet..</p>
    </div>
    <div class="col-sm-4">
      <span class="glyphicon glyphicon-wrench logo-small"></span>
      <h4 style="color:#303030;">HARD WORK</h4>
      <p>Lorem ipsum dolor sit amet..</p>
    </div>
  </div>
  -->
</div>

<!-- Container (Portfolio Section) -->
<!--
  <h2>Portfolio</h2><br>
  <h4>What we have created</h4>
  <div class="row text-center slideanim">
    <div class="col-sm-4">
      <div class="thumbnail">
        <img src="paris.jpg" alt="Paris" width="400" height="300">
        <p><strong>Paris</strong></p>
        <p>Yes, we built Paris</p>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="thumbnail">
        <img src="newyork.jpg" alt="New York" width="400" height="300">
        <p><strong>New York</strong></p>
        <p>We built New York</p>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="thumbnail">
        <img src="sanfran.jpg" alt="San Francisco" width="400" height="300">
        <p><strong>San Francisco</strong></p>
        <p>Yes, San Fran is ours</p>
      </div>
    </div>
  </div><br>
-->
<div id="portfolio" class="container-fluid text-center bg-grey">


  <h2>What our customers say</h2>
  <div id="myCarousel" class="carousel slide text-center" data-ride="carousel">
    <!-- Indicators -->
    <ol class="carousel-indicators">
      <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
      <li data-target="#myCarousel" data-slide-to="1"></li>
    </ol>

    <!-- Wrapper for slides -->
    <div class="carousel-inner" role="listbox">
      <div class="item active">
        <h4>"Here will be a nice comment!"<br><span>Adi Tenea, Dev</span></h4>
      </div>
      <div class="item">
        <h4>"We have a second one... WOW!!"<br><span>Adi Tenea, Dev</span></h4>
      </div>
    </div>

    <!-- Left and right controls -->
    <a class="left carousel-control" href="#myCarousel" role="button" data-slide="prev">
      <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="right carousel-control" href="#myCarousel" role="button" data-slide="next">
      <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</div>

<!-- Container (Pricing Section) -->
<div id="pricing" class="container-fluid">
  <div class="text-center">
    <h2>Pricing</h2>
    <h4>Choose a payment plan that works for you</h4>
  </div>
  <div class="row ">
    <div class="col-sm-4 col-xs-12">
      <div class="panel panel-default text-center">
        <div class="panel-heading">
          <h1>Beta</h1>
        </div>
        <div class="panel-body">
          <p><strong>20</strong> Tables</p>
          <p><strong>1000</strong> Records</p>
          <p><strong>5</strong> Services</p>
        </div>
        <div class="panel-footer">
          <h3>$0</h3>
          <h4>per month</h4>
          <button class="btn btn-lg">Sign Up</button>
        </div>
      </div>
    </div>
    <div class="col-sm-4 col-xs-12">
      <div class="panel panel-default text-center">
        <div class="panel-heading">
          <h1>Basic</h1>
        </div>
        <div class="panel-body">
          <p><strong>?</strong> Tables</p>
          <p><strong>?</strong> Records</p>
          <p><strong>?</strong> Services</p>
        </div>
        <div class="panel-footer">
          <h3>$00</h3>
          <h4>per month</h4>
          <button class="btn btn-lg">Sign Up</button>
        </div>
      </div>
    </div>
    <div class="col-sm-4 col-xs-12">
      <div class="panel panel-default text-center">
        <div class="panel-heading">
          <h1>Pro</h1>
        </div>
        <div class="panel-body">
          <p><strong>?</strong> Tables</p>
          <p><strong>?</strong> Records</p>
          <p><strong>?</strong> Services</p>
        </div>
        <div class="panel-footer">
          <h3>$00</h3>
          <h4>per month</h4>
          <button class="btn btn-lg">Sign Up</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Container (Contact Section) -->
<!--
<div id="contact" class="container-fluid bg-grey">
  <h2 class="text-center">CONTACT</h2>
  <div class="row">
    <div class="col-sm-5">
      <p>Contact us and we'll get back to you within 24 hours.</p>
      <p><span class="glyphicon glyphicon-map-marker"></span> Chicago, US</p>
      <p><span class="glyphicon glyphicon-phone"></span> +00 1515151515</p>
      <p><span class="glyphicon glyphicon-envelope"></span> myemail@something.com</p>
    </div>
    <div class="col-sm-7 slideanim">
      <div class="row">
        <div class="col-sm-6 form-group">
          <input class="form-control" id="name" name="name" placeholder="Name" type="text" required>
        </div>
        <div class="col-sm-6 form-group">
          <input class="form-control" id="email" name="email" placeholder="Email" type="email" required>
        </div>
      </div>
      <textarea class="form-control" id="comments" name="comments" placeholder="Comment" rows="5"></textarea><br>
      <div class="row">
        <div class="col-sm-12 form-group">
          <button class="btn btn-default pull-right" type="submit">Send</button>
        </div>
      </div>
    </div>
  </div>
</div>
-->



<!-- Image of location/map -->
<!--
<img src="/w3images/map.jpg" class="w3-image w3-greyscale-min" style="width:100%">
-->

<footer class="container-fluid text-center">
  <a href="#myPage" title="To Top">
    <span class="glyphicon glyphicon-chevron-up"></span>
  </a>
  <p>NotesBook Made By <a href="https://www.aditenea.net" title="Visit aditenea.net">www.aditenea.net</a></p>
</footer>




<script>
  $(document).ready(function () {
    // Add smooth scrolling to all links in navbar + footer link
    $(".navbar a, footer a[href='#myPage']").on('click', function (event) {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (900) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 900, function () {

          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      } // End if
    });

    $(window).scroll(function () {
      $(".slideanim").each(function () {
        var pos = $(this).offset().top;

        var winTop = $(window).scrollTop();
        if (pos < winTop + 600) {
          $(this).addClass("slide");
        }
      });
    });
  })
</script>
