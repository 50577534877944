<div class="text-center pageTitle1">
  <br>
  <h1>Note: {{noteBookDto.noteBook.name}}</h1>
  <hr>
</div>
<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Data">
    <div *ngIf="noteBookDtoLoaded" style="margin: 15px;">
      <h1 class="text-center"> {{noteBookDto.noteBook.name}}</h1>
      <hr>
      <table class="table table-responsive table-bordered" style="width: 80%; margin: auto;">
        <thead class="pageTitle1">
          <tr>
            <th>#</th>
            <th *ngFor="let column of tableData.columns">{{column.name}}</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="tableData.records != []">
          <tr *ngFor="let record of tableData.records; let i = index">
            <th>{{i+1}}</th>
            <td *ngFor="let column of tableData.columns">{{record[column.key]}}</td>
            <td>
              <div class="btn-group" role="group" aria-label="Basic example" style="margin:auto;">
                <button type="button" class="btn btn-danger" (click)="removeRecord(record.id)">Delete</button>
                <button type="button" class="btn btn-warning" (click)="updateRecord(record.id)">Modify</button>
              </div>
            </td>
          </tr>
        </tbody>
        <div *ngIf="tableData.records.length <= 0" class="text-center" style="margin:auto;padding: 15px;">
          <h3>No data!</h3>
        </div>
      </table>
    </div>
  </mat-tab>
  <mat-tab label="Add record">
    <div *ngIf="noteBookDtoLoaded" style="margin: 15px;">
      <h1 class="text-center"> Add record</h1>
      <hr>
      <app-note-record-form [columns]="noteBookDto.noteColumns" (onSubmitEvent)="addRecord($event)">
      </app-note-record-form>
    </div>
  </mat-tab>
  <mat-tab label="Modify">
    <div *ngIf="noteBookDtoLoaded" style="margin: 15px;">
      <h1 class="text-center"> Modify Table Settings</h1>
      <hr>
      <div>

      </div>
    </div>

    <mat-card class="example-card" style="background-color: #7986cb;color: white;max-width: 80%;margin:auto">
      <mat-card-title>Columns</mat-card-title>
      <mat-card-content>
        <p>Description:</p>
        <!--
        <mat-card class="example-card" style="max-width: 80%;margin:auto">
          <mat-card-content *ngFor="let column of columnsList">
            Name: {{column.name}} | Type: {{column.type}} | <button class="btn btn-danger">Delete</button>
          </mat-card-content>
        </mat-card>
        -->

        <mat-chip-list #chipList aria-label="Columns">
          <mat-chip *ngFor="let column of columnsList" [selectable]="false">
            <button mat-button (click)="columnMovePosition(column.id,0)">
              <mat-icon>arrow_back_ios</mat-icon>
            </button>

            {{column.name}} | {{column.type}}

            <button mat-button data-toggle="modal" data-target="#confirmDeleteColumnModal"
              (click)="removeColumnOfTable(column)">
              <mat-icon>cancel</mat-icon>
            </button>

            <button mat-button (click)="columnMovePosition(column.id, 1)">
              <mat-icon>arrow_forward_ios</mat-icon>
            </button>

          </mat-chip>
        </mat-chip-list>

      </mat-card-content>
      <hr>
      <mat-card-actions>
        <button mat-button (click)="addColumn()">Add</button>
      </mat-card-actions>
      <mat-card-footer>
      </mat-card-footer>
    </mat-card>
    <br>

    <mat-card class="example-card" style="background-color: #7986cb;color: white;max-width: 80%;margin:auto;">
      <mat-card-title>Tabel Info Update</mat-card-title>
      <mat-card-content style="margin:0;color:
      white;">
        <div>
          <br>
          <app-generic-form style="width: 100%;" [fields]="noteBookDataForm.fields"
            [actionName]="noteBookDataForm.actionName" (onSubmitEvent)="updateNoteBookData($event)">
          </app-generic-form>
          <br>
        </div>

      </mat-card-content>
      <mat-card-footer>
      </mat-card-footer>
    </mat-card>


  </mat-tab>
</mat-tab-group>
