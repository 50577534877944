import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NoteRecordDto } from 'src/app/models/dto/noteRecordDtoModel';
import { NoteColumn } from 'src/app/models/standard-model/noteColumnModel';
import { GenericFormControlService } from 'src/app/services/generic-form-serive';
import { GenericFieldBase } from '../generic-fields/generic-field-base';

@Component({
  selector: 'app-generic-form',
  templateUrl: './generic-form.component.html',
  styleUrls: ['./generic-form.component.scss']
})
export class GenericFormComponent implements OnInit {

  @Input() fields: any[] = []; //[{displayName:"name", key:"key1", value: "", type: "string", disabled: true}, {displayName:"nameList", key:"key1", value:"", type: "dropdown", list: [{display: "value1", value: "valueKey1"}, {display: "value2", value: "valueKey2"}]}] /
  @Input() actionName: string = "Submit";

  @Output() onSubmitEvent = new EventEmitter<NoteRecordDto>();

  allDataReady: boolean = false;


  genericForm: FormGroup = new FormGroup({});

  constructor() { }

  ngOnInit(): void {
    //  console.log("fields");
    // console.log(this.fields);
    this.generateFormGroup(this.fields);
    this.allDataReady = true;
  }


  generateFormGroup(fields: any[]) {
    let newGroup: any = {};
    fields.forEach(field => {
      newGroup[field.key] = new FormControl('');
      newGroup[field.key].value = field.value;
      // Start posibility to make the field disabled
      if (field.disabled != undefined) {
        try {
          if (field.disabled) {
            newGroup[field.key].disable();
          }
        }
        catch {

        }
      }
      // End posibility to make the field disabled
    })
    this.genericForm = new FormGroup(newGroup);
  }

  onSubmit() {
    // console.log(this.genericForm.value);
    this.onSubmitEvent.emit(this.genericForm.value);
  }

}
