import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AdminPannelComponent } from './components/pages/admin-pannel/admin-pannel.component';
import { HomeComponent } from './components/pages/home/home.component';
import { LoginComponent } from './components/pages/login/login.component';
import { NoteBookDetailsComponent } from './components/pages/note-book-details/note-book-details.component';
import { NotesMainComponent } from './components/pages/notes-main/notes-main.component';
import { RegisterComponent } from './components/pages/register/register.component';
import {
  BasicAuthGuardService as BasicAuthGuard
} from './services/base-auth-guard.service';
import {
  AdminAuthGuardService as AdminAuthGuard
} from './services/admin-auth-guard.service';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';

const routes: Routes = [
  //{ path: 'admin', component: AdminPanelComponent, canActivate: [AdminGuard]  },
  //{ path: 'register', component: UserDataFormComponent },
  //{ path: 'studentInfo/:id', component: StudentInfoComponent, canActivate: [AdminGuard] }, //https://angular.io/start/start-routing
  //{ path: 'classRoomInfo/:id', component: ClassroomInfoComponent, canActivate: [AdminGuard] }, //https://angular.io/start/start-routing
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'notes', component: NotesMainComponent, canActivate: [BasicAuthGuard] },
  { path: 'note/:id', component: NoteBookDetailsComponent, canActivate: [BasicAuthGuard] },
  { path: 'home', component: HomeComponent },
  { path: 'admin', component: AdminPannelComponent, canActivate: [AdminAuthGuard] },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' } // 404
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
