<div *ngIf="allDataReady">

</div>
<form (ngSubmit)='onSubmit()' [formGroup]='genericForm' novalidate class="form" style="margin: auto;width: 70%;">
  <mat-form-field *ngFor="let field of fields" [ngSwitch]="field.type" appearance="fill"
    style="margin: auto;width: 100%;" style="width: 100%;">
    <mat-label>{{field.displayName}}</mat-label>
    <input *ngSwitchCase="'text'" [formControlName]="field.key" type="text" placeholder='{{field.displayName}}'
      [value]="field.value" matInput>
    <input *ngSwitchCase="'password'" [formControlName]="field.key" type="password" placeholder='{{field.displayName}}'
      [value]="field.value" matInput>
    <input *ngSwitchCase="'number'" [formControlName]="field.key" type="number" placeholder='{{field.displayName}}'
      [value]="field.value" matInput>
    <mat-select *ngSwitchCase="'dropdown'" [formControlName]='field.key' [value]="field.value">
      <mat-option *ngFor="let elm of field.list" [value]="elm.value">
        {{elm.display}}
      </mat-option>
    </mat-select>
    <div *ngSwitchCase="'datetime'">
      <input matInput [matDatepicker]="picker" [formControlName]="field.key">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </mat-form-field>
  <br>
  <button class="btn btn-default">{{actionName}}</button>
</form>
