import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class TokenActionsService {
  // https://www.npmjs.com/package/jwt-decode


  constructor() {

  }

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  deleteToken() {
    localStorage.removeItem('token');
  }

  getCurrentEmail() {
    try {
      let token = this.getToken();
      if (token != null) {
        return this.getDecodedAccessToken(token)['email'];
      }
      return null;
    }
    catch {
      return null;
    }
  }

  getCurrentRank() {
    try {
      let token = this.getToken();
      if (token != null && this.isInTerm(token)) {
        return this.getDecodedAccessToken(token)['rank'];
      }
      return null;
    }
    catch {
      return null;
    }
  }

  getCurrentUserId() {
    try {
      let token = this.getToken();
      if (token != null) {
        return this.getDecodedAccessToken(token)['id'];
      }
      return null;
    }
    catch {
      return null;

    }

  }

  public isAuthenticated(rankCheck?: string): boolean {
    let token = this.getToken();

    if (token != null) {
      if (!rankCheck) {
        if (this.isInTerm(token)) {
          return true;
        }
      }
      else {
        if (this.haveRank(token, rankCheck) && this.isInTerm(token)) {
          return true;
        }
      }
      return false;
    }
    return false;
  }




  private isInTerm(token: any): boolean {
    token = this.getDecodedAccessToken(token);
    let expiryDate = new Date(token['exp'] * 1000);

    //console.log(new Date(token['exp']*1000) + " - " +  new Date());

    if (expiryDate < new Date()) {
      console.log("The token has expired!");
      this.deleteToken();
      return false;
    }
    return true;
  }

  private haveRank(token: any, rank: string): boolean {
    try {
      token = this.getDecodedAccessToken(token);
      if (token['rank'] != rank) {
        console.log("The rank is not correct!");
        return false;
      }
      return true;
    }
    catch {
      return false;
    }

  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
    return null;
  }

}
