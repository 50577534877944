import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpGenericService } from 'src/app/services/http-generic-service';
import { TokenActionsService } from 'src/app/services/token-actions-service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: any = { fields: [{ displayName: "Email", key: "email", value: "", type: "text" }, { displayName: "Password", key: "passwordHash", value: "", type: "password" }, { displayName: "Full Name", key: "fullName", value: "", type: "text" }, { displayName: "Registration Token", key: "registrationToken", value: "", type: "text" }], actionName: "Register" };//{ fields: [{ displayName: "Email", key: "email", value: "", type: "text" }, { displayName: "Password", key: "passwordHash", value: "", type: "password" }, { displayName: "Full Name", key: "fullName", value: "", type: "text" }, { displayName: "Rank", key: "rank", value: "user", type: "text", disabled: true }], actionName: "Register" };

  errorsList = "";
  haveErrrors = false;

  constructor(private router: Router, private httpService: HttpGenericService<any>, private tokenService: TokenActionsService) { }

  ngOnInit(): void {
    if (this.tokenService.getToken() != null) {
      this.router.navigate(['/home']);
    }
    else {

    }
  }


  register(registerFormData: any) {
    registerFormData.rank = "user";
    console.log(registerFormData);
    this.httpService.post("Auth/Register", registerFormData).subscribe(response => {
      this.tokenService.setToken(response.token);
      console.log(response.token);
      this.router.navigate(['/home']);
      this.reloadCurrentPage();
    },
      error => {
        this.error(error);
      })
  }

  reloadCurrentPage() {
    window.location.reload();
  }

  error(errorMessage: any) {
    switch (errorMessage.status) {
      case 404:
        this.errorsList = "Invalid user!";
        break;
      default:
        this.errorsList = "Error: " + errorMessage.status;
        break;
    }
    this.haveErrrors = true;
    console.log(errorMessage);
  }


}

