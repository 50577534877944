import { getLocaleDateTimeFormat } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { RegistrationTokenModel } from 'src/app/models/standard-model/registrationToken';
import { UserModel } from 'src/app/models/standard-model/userModel';
import { HttpGenericService } from 'src/app/services/http-generic-service';

@Component({
  selector: 'app-admin-pannel',
  templateUrl: './admin-pannel.component.html',
  styleUrls: ['./admin-pannel.component.scss']
})
export class AdminPannelComponent implements OnInit {

  registrationTokenForm: any = {
    fields: [{ displayName: "Name", key: "name", value: "", type: "text" },
    { displayName: "Expiration Date", key: "expirationDate", value: new Date, type: "datetime" },
    { displayName: "Rank", key: "rank", value: "", type: "dropdown", list: [{ display: "User", value: "user" }, { display: "Admin", value: "admin" }] }], actionName: "Add"
  };//{ fields: [{ displayName: "Email", key: "email", value: "", type: "text" }, { displayName: "Password", key: "passwordHash", value: "", type: "password" }, { displayName: "Full Name", key: "fullName", value: "", type: "text" }, { displayName: "Rank", key: "rank", value: "user", type: "text", disabled: true }], actionName: "Register" };

  registrationTokenTable: any = {
    tableColumns: [{ "displayName": "Name", "key": "name" },
    { "displayName": "Rank", "key": "rank" },
    { "displayName": "Token", "key": "token" },
    { "displayName": "Expiration date", "key": "expirationDate" }],
    tableActions: ["Delete"],
    tableData: []
  }

  usersTable: any = {
    tableColumns: [{ "displayName": "Full Name", "key": "fullName" },
    { "displayName": "Email", "key": "email" },
    { "displayName": "Rank", "key": "rank" },],
    tableActions: ["Delete"],
    tableData: []
  }

  registrationToken: RegistrationTokenModel[] = [];
  users: UserModel[] = [];

  loadedUsers: boolean = false;
  loadedRegistrationTokens: boolean = false;


  constructor(private registrationTokenHttpService: HttpGenericService<RegistrationTokenModel>,
    private userHttpService: HttpGenericService<UserModel>) { }

  ngOnInit(): void {
    this.loadRegistrationTokens();
    this.loadUsers();
  }


  addToken(data: any) { //RegistrationTokenModel
    console.log(data);
    this.registrationTokenHttpService.post("Auth/RegistrationToken", data).subscribe(response => {
      console.log(response);
      this.reloadCurrentPage();
    });
  }

  loadRegistrationTokens() {
    this.registrationTokenHttpService.get("Auth/RegistrationToken").subscribe(response => {
      console.log(response);
      this.registrationToken = response;
      this.registrationTokenTable.tableData = response;
      this.loadedRegistrationTokens = true;
      console.log(this.registrationTokenTable);
    })
  }

  loadUsers() {
    this.userHttpService.get("Auth/Users").subscribe(response => {
      console.log(response);
      this.users = response;
      this.usersTable.tableData = response;
      this.loadedUsers = true;
      console.log(this.usersTable);

    })
  }


  reloadCurrentPage() {
    window.location.reload();
  }

}
