import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/models/standard-model/userModel';
import { HttpGenericService } from 'src/app/services/http-generic-service';
import { TokenActionsService } from 'src/app/services/token-actions-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: any = { fields: [{ displayName: "Email", key: "email", value: "", type: "text" }, { displayName: "Password", key: "password", value: "", type: "password" }], actionName: "Log In" };
  userDataToShow: any;
  errorsList = "";
  haveErrrors = false;

  constructor(private router: Router, private httpService: HttpGenericService<any>, private tokenService: TokenActionsService) { }

  ngOnInit(): void {
    if (this.tokenService.getToken() != null) {
      this.pagesRedirection(this.tokenService.getCurrentRank());
    }
    else {

    }
  }

  loginUser(loginFormData: any) {
    console.log(loginFormData);
    this.httpService.post("Auth/Login", loginFormData).subscribe(response => {
      this.tokenService.setToken(response.token);
      this.reloadCurrentPage();
    },
      error => {
        this.error(error);
      })
  }

  error(errorMessage: any) {
    switch (errorMessage.status) {
      case 404:
        this.errorsList = "Invalid user!";
        break;
      default:
        this.errorsList = "Error: " + errorMessage.status;
        break;
    }
    this.haveErrrors = true;
    console.log(errorMessage);
  }

  pagesRedirection(rank: string) {
    console.log(rank);
    if (rank == null || rank == "") {
      this.tokenService.deleteToken();
      this.error("Invalid user! Please log in!");
    }
    else {
      switch (rank) {
        case "admin": {
          //this.router.navigate(['/admin']);
          this.router.navigate(['/home']);
          break;
        }
        case "teacher": {
          this.tokenService.deleteToken();
          this.error("The pages for this user are not implemented!");
          break;
        }
        case "user": {
          //this.router.navigate(['/notes']);
          this.router.navigate(['/home']);
          break;
        }
        default: {
          this.tokenService.deleteToken();
          this.error("Invalid user! Please log in!");
          break;
        }
      }
    }
  }

  reloadCurrentPage() {
    window.location.reload();
  }



}
