import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { TokenActionsService } from './token-actions-service';

@Injectable({
  providedIn: 'root'
})

export class BasicAuthGuardService implements CanActivate {
  constructor(public tokenService: TokenActionsService, public router: Router) { }

  canActivate(): boolean {
    if (!this.tokenService.isAuthenticated()) {
      this.tokenService.deleteToken();
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
