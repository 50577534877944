import { BaseModel } from "./baseModel";

export class NoteColumn extends BaseModel {
  constructor() {
    super();
    this.noteBookId = 0;
    this.orderNumber = 0;
    this.name = "";
    this.type = "";
    this.key = "";
    this.id = 0;
    this.registrationDate = new Date();
    this.modifiedDate = new Date();
    this.userId = 0;
  }

  public getTypes() {
    return ["number", "text", "datetime"];
  }

  "noteBookId": number;
  "orderNumber": number;
  "name": string;
  "key": string;
  "type": string;
  "userId": number;
}
