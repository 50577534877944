<nav class="navbar navbar-inverse " style="border-radius: 0%;margin:0%;background-color: #4a569c;border:0;">
  <!--#5c6599-->
  <!-- navbar-fixed-top -->
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <a class="navbar-brand" href="#">NotesBook</a>
    </div>
    <div class="collapse navbar-collapse" id="myNavbar">
      <ul class="nav navbar-nav">
        <li><a href="#" routerLink="/home" routerLinkActive="active">Home</a></li>
        <li><a href="#" routerLink="/notes" routerLinkActive="active">Notes</a></li>
        <li><a *ngIf="loggedIn && actualRank == 'admin'" href="#" routerLink="/admin"
            routerLinkActive="active">Admin</a></li>

        <!--

        <li class="dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown" href="#">Page 1 <span class="caret"></span></a>
          <ul class="dropdown-menu">
            <li><a href="#">Page 1-1</a></li>
            <li><a href="#">Page 1-2</a></li>
            <li><a href="#">Page 1-3</a></li>
          </ul>
        </li>
        <li><a href="#">Page 2</a></li>
        <li><a href="#">Page 3</a></li>
        -->
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="loggedIn"><a href="#"><span class="glyphicon glyphicon-user"></span> User: {{actualUser}}</a></li>
        <li *ngIf="loggedIn" (click)="logOut()"><a href="#"><span class="glyphicon glyphicon-log-out"></span> Logout</a>
        </li>
        <li *ngIf="!loggedIn"><a routerLink="/login" routerLinkActive="active"><span
              class="glyphicon glyphicon-log-in"></span> Login</a></li>
        <li *ngIf="!loggedIn"><a routerLink="/register" routerLinkActive="active"><span
              class="glyphicon glyphicon-log-in"></span> Register</a></li>
      </ul>
    </div>
  </div>
</nav>
<div class="container-fluid imageBg" style="min-height:100%;">
  <div class="row">
    <div class="col-sm-1"></div>
    <div class="col-sm-10"
      style="background-color:white;margin: 0;padding: 0;min-height:1000px;box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);">
      <router-outlet></router-outlet>
    </div>
    <div class="col-sm-1"></div>
  </div>
</div>
