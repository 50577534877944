import { NoteBook } from "../standard-model/noteBookModel";
import { NoteCell } from "../standard-model/noteCellModel";
import { NoteColumn } from "../standard-model/noteColumnModel";
import { NoteRecordDto } from "./noteRecordDtoModel";

export class NoteBookDto {

  constructor() {
    this.noteBook = new NoteBook;
    this.noteColumns = [];
    this.noteRecords = [];
  }

  "noteBook": NoteBook;
  "noteColumns": NoteColumn[];
  "noteRecords": NoteRecordDto[];
}
