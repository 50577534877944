<table class="table table-responsive table-bordered" style="width: 80%; margin: auto;">
  <thead class="pageTitle1">
    <tr>
      <th>#</th>
      <th *ngFor="let column of tableColumns">{{column.displayName}}</th>
      <th *ngIf="tableActions.length > 0">Actions</th>
    </tr>
  </thead>
  <tbody *ngIf="tableData != []">
    <tr *ngFor="let record of tableData; let i = index">
      <th>{{i+1}}</th>
      <td *ngFor="let column of tableColumns">{{record[column.key]}}</td>

      <td *ngIf="tableActions.length > 0">
        <div class="btn-group" role="group" aria-label="Basic example" style="margin:auto;">
          <button *ngFor="let action of tableActions" type="button" class="btn btn-danger"
            (click)="onAction(record.id, action)">{{action}}</button>
        </div>
      </td>
    </tr>
  </tbody>
  <div *ngIf="tableData.length <= 0" class="text-center" style="margin:auto;padding: 15px;">
    <h3>No data!</h3>
  </div>
</table>
